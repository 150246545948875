export const removeWhitespace = htmlInput => {
  return htmlInput
    .replace(/\n/g, '')
    .replace(/\r/g, '')
    .replace(/\s+/, ' ')
    .replace(/>\s*</g, '><')
    .replace(/<p>\s*&nbsp;\s*<\/p>/g, '<br>')
    .replace(/<\/*br\s*\/*>/g, '<br>')
    .replace(/(<br>)+/g, '<br>')
    .replace(/<\/p><br><p><br>/g, '</p><p><br>')
    .replace(/(<br>)+<option>/g, '<br><option>')
    .replace(/(<br>\s*)+$/, '')
    .trim()
}

export const parseOptionTags = htmlInput => {
  const optionsRegex = /<option>.[^<]*<\/option>/gm
  const prefixRegex = /.*?(?=<option)/
  const suffixRegex = /(?:(?!(<\/option>)).)+$/gm

  let prefix, suffix
  let options = []

  if (htmlInput) {
    // find all matches shaped <option>...</option>
    options = htmlInput.match(optionsRegex)

    if (options && options.length > 0) {
      // remove the <option> tags
      options = options.map(option =>
        option.replace(/<option>/g, '').replace(/<\/option>/g, '')
      )

      // get content before the options
      prefix = htmlInput.match(prefixRegex)
      prefix = prefix && prefix[0] ? prefix[0] : undefined

      // get content after the options
      suffix = htmlInput.match(suffixRegex)
      if (suffix && suffix[0] && suffix[0] !== '/option>') {
        suffix = suffix[0].replace(/\/option>/g, '')
      } else {
        suffix = undefined
      }
    } else {
      options = []
      // in case of no options return everything as answer text
      prefix = htmlInput
    }
  }

  return { prefix, suffix, options }
}

export const removeStyleInformation = html => {
  const regex = /(?:\s+(?:style|class)\s*=\s*"[^"]*")/gi

  return html.replace(regex, '')
}
